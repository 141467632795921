<template>
  <div class="container">
    <div class="index_section setPass_cs">
        <van-nav-bar
          title="修改密码"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        />
        <div class="iptF_cs1">
          <div class="ipt_cs">
            <van-form>
                <van-field
                  v-model.trim="form.originalPass"
                  :type="passwordOff0?'password':'text'"
                  name="原始密码"
                  maxlength="18"
                  placeholder="请输入原始密码"
                >
                  <template slot="right-icon">
                    <img :src="passwordOff0?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="18px" height="18px" alt="" @click="passwordOff0=!passwordOff0">
                  </template>
                </van-field>
              </van-form>
          </div>
          <div class="ipt_cs">
            <van-form>
                <van-field
                  v-model.trim="form.newPass"
                  :type="passwordOff1?'password':'text'"
                  name="新密码"
                  maxlength="18"
                  placeholder="请输入新密码"
                >
                  <template slot="right-icon">
                    <img :src="passwordOff1?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="18px" height="18px" alt="" @click="passwordOff1=!passwordOff1">
                  </template>
                </van-field>
              </van-form>
          </div>
          <div class="ipt_cs">
            <van-form>
                <van-field
                  v-model.trim="form.affirmPass"
                  :type="passwordOff2?'password':'text'"
                  name="确认密码"
                  maxlength="18"
                  placeholder="请输入确认密码"
                >
                  <template slot="right-icon">
                    <img :src="passwordOff2?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="18px" height="18px" alt="" @click="passwordOff2=!passwordOff2">
                  </template>
                </van-field>
              </van-form>
          </div>
          <div class="btn_cs">
            <van-button type="info" style="border-radius: 50px; width: 100%;" v-if="didabledBtn" @click="nextPass">确认修改</van-button>
            <van-button type="info" style="border-radius: 50px; width: 100%;" disabled v-else>确认修改</van-button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
let posswordReg = /^(\w){6,18}$/ //只能输入6-18位字母、数字、下划线
import { Notify } from 'vant';
export default {
  data() {
    return {
      didabledBtn: true,
      passwordOff0: true,
      passwordOff1: true,
      passwordOff2: true,
      // 修改密码
      form:{
        originalPass: '',
        newPass: '',
        affirmPass: '',
      },
    }
  },
  methods: {
      // 确认修改
      nextPass(){
        if (!posswordReg.test(this.form.originalPass)) {
          Notify({ type: 'danger', message: '旧密码输入格式错误' })
          return false
        }
        if (!posswordReg.test(this.form.newPass)) {
          Notify({ type: 'danger', message: '新密码输入格式错误' })
          return false
        }
        if (this.form.originalPass == this.form.newPass) {
          Notify({ type: 'danger', message: '新密码和旧密码不能相同' })
          return false
        }
        if (this.form.newPass != this.form.affirmPass) {
          Notify({ type: 'danger', message: '两次密码输入不一致' })
          return false
        }
        this.didabledBtn = false;
        this.$http
          .ajax(
            '/ffkj-main/merchant/updatePassword',
            {
              oldPassword: this.$md5(this.form.originalPass),
              newPassword: this.$md5(this.form.affirmPass),
              phone: window.localStorage.getItem('ms_phone')
            },
            'post',
            {}
          )
          .then(res => {
            if (res.code == 0) {
              Notify({ type: 'success', message: '密码修改成功' })
              setTimeout(
                function () {
                  this.setExit()
                }.bind(this),
                500
              )
            } else {
              this.didabledBtn = true;
              Notify({ type: 'danger', message: res.message })
            }
          })

      },
    // 密码修改成功退出登录
    setExit(){
        this.$http
          .ajax(
            '/ffkj-main/merchant/unbind',
            {
              openId: window.localStorage.openId
            },
            'get',
            {}
          )
          .then(res => {
            if (res.code == 0) {
              this.$router.push('/login')
            } else {
              this.didabledBtn = true;
              Notify({ type: 'danger', message: '退出登录失败!!!' })
            }
          })
      },

      // 返回
      onClickLeft() {
        this.$router.push({
          path: '/mine',
          query: {}
        })
      },
  }
}
</script>
<style >
  .el-textarea__inner,.el-input__inner { 
    background: transparent !important;
  }
</style>
<style scoped>
</style>